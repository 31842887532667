import { apiClient } from '../ApiClient';
import { TrackModelView } from "./types"
import { ApiResponse, RequestPayload } from "../ApiClient/types"

class TrackService {
  /** 
   * Returns the tracking info for a particular route stop
   * @param payload = RequestPayload<null>
   * @param queryParams =  - string
   * @param queryParams.tInfo - string
   * @param queryParams.fromDate - string (optional)
   * @param queryParams.toDate - string (optional)
   * @example
   * ```ts
   * let query: TrackingInfoQueryParams = {
   *   findBy: TrackingCatagory.CustomerReference,
   *   tInfo: '123412351251245\r\n12312413515',
   *   fromDate: '',
   *   toDate: ''   * 
   * };
   * let payload: RequestPayload<null> = {  queryParams: query, payload: null  };
   * ```
   * 
   * @returns TrackModelView[]
   */
  async GetTrackingInfo(payload: RequestPayload<null>): Promise<ApiResponse<TrackModelView[]>> {
    return apiClient.get<TrackModelView[]> (
      '/track/',
      payload
    );
  }

}

export { TrackService }