export const getFileName = (name: any): string => {
  let filename = 'default_filename.txt'; // default filename
  if (name) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(name);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
  }
  return filename;
}