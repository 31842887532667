import { apiClient } from '../ApiClient';
import { ApiResponse, RequestPayload } from "../ApiClient/types"
import { UserPreference, User } from '../types';

class PreferencesService {
    /**
     * Returns user preferences
     * @param payload = RequestPayload<null>
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { payload: null }
     * ```
     * @returns 
     */
    async GetUserPreferences(payload: RequestPayload<null>) : Promise<ApiResponse<User>> {
        return apiClient.get<User> (
            '/preferences/',
            payload
        )
    }

    /**
     * Updates user preferences
     * @param payload UserPreference[]
     * @returns UserPreferenceView
     */
    async UpdateUserPreferences(payload: RequestPayload<UserPreference[]>): Promise<ApiResponse<User>> {
        return apiClient.post<User> (
            '/preferences/',
            payload
        )
    }

}

export { PreferencesService }