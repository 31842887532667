import { apiClient } from '../ApiClient';
import { Route, Courier, RouteInfo, RouteDetailsModelView, RouteStopView, RouteStopDetailsViewModel } from "../RoutesService/types"
import { ApiResponse, RequestPayload } from "../ApiClient/types"

class RouteService {
    /**
     * Returns list of couriers for the particular customer
     * @param payload  = RequestPayload<null> 
     * @param queryParams - RouteSearchQueryParams
     * @example
     * ```ts
     * let query: RouteSearchQueryParams = { 
     *   pDate: '02/23/2023', 
     *   searchBy: 'location-name', 
     *   searchFor: 'LabCore' };
     * let payload: RequestPayload<null> = { queryParams: query, payload: null };
     * ```
     * @returns Courier[]
     */ 
    async GetCourierList(payload: RequestPayload<null>): Promise<ApiResponse<Courier[]>> {
      return apiClient.get<Courier[]> (
        '/route/courier/',
        payload
      );
    }

    /**
     * Returns list of routes for the particular customer
     * @param payload  = RequestPayload<null> 
     * @param queryParams: RouteSearchQueryParams
     * @example
     * ```ts
     * let query: RouteSearchQueryParams = { 
     *   pDate: '02/23/2023', 
     *   searchBy: 'location-name', 
     *   searchFor: 'LabCore' };
     * 
     * let payload: RequestPayload<null> = { queryParams: query, payload: null };
     * ```
     * 
     * @returns Route[]
     */ 
    async GetRouteList(payload: RequestPayload<null>): Promise<ApiResponse<Route[]>> {
      return apiClient.get<Route[]> (
        '/route/list/',
        payload
      );
    }

    /**
     * Returns list of map information for customer routes
     * @param payload  = RequestPayload<null> 
     * @param queryParams = pDate - string needs to be in the format MM/DD/YYYY (optional)
     * @example
     * ```ts 
     * let payload: RequestPayload<null> = { queryParams: { pDate: '02/23/2023' }, payload: null };
     * ```
     * 
     * @returns RouteInfo[]
     */ 
    async GetMapList(payload: RequestPayload<null>): Promise<ApiResponse<RouteInfo[]>> {
      return apiClient.get<RouteInfo[]> (
        '/route/map/',
        payload
      );
    }
    
    /**
     * Returns stop details for specific route
     * @param payload  = RequestPayload<null> 
     * @param queryParams = StopListQueryParams
     * @example
     * ```ts
     * let query: StopListQueryParams = {
     *   routeId: 134,
     *   pDate: '02/23/2023'
     * };
     * 
     * let payload: RequestPayload<null> = { queryParams: query, payload: null };
     * ```
     * 
     * @returns RouteDetailsModelView
     */ 
    async GetStopList(payload: RequestPayload<null>): Promise<ApiResponse<RouteDetailsModelView>> {
      return apiClient.get<RouteDetailsModelView> (
        '/route/stops/',
        payload
      );
    }

    /**
     * Returns list of route stops used for rendering route map
     * @param payload  = RequestPayload<null> 
     * @param queryParams = StopListQueryParams
     * @example
     * ```ts
     * let query: StopListQueryParams = {
     *   routeId: 134,
     *   pDate: '02/23/2023'
     * };
     * 
     * let payload: RequestPayload<null> = { queryParams: query, payload: null };
     * ```
     * 
     * @returns RouteStopView[]
     */ 
    async GetRouteMap(payload: RequestPayload<null>): Promise<ApiResponse<RouteStopView[]>> {
      return apiClient.get<RouteStopView[]> (
        '/route/routemap/',
        payload
      );
    }

    /**
     * Returns details for a particular route stop
     * @param payload  = RequestPayload<null> 
     * @param queryParams = stopId: number (required)
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { queryParams: { stopId: 113 }, payload: null };
     * ```
     * 
     * @returns RouteStopDetailsViewModel
     */ 
    async GetStopDetails(payload: RequestPayload<null>): Promise<ApiResponse<RouteStopDetailsViewModel>> {
      return apiClient.get<RouteStopDetailsViewModel> (
        '/route/stop/',
        payload
      );
    }
}

export { RouteService }