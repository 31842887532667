import axios, { AxiosRequestConfig, ResponseType } from 'axios'
import { ApiResponse, ApiRequestParams, RequestHeader } from './types';
import { isTokenExpired } from '../../utils/token';
import { AuthenticateResponse } from '../AccountService/types';
import { getFileName } from '../../utils/getfilename';

async function apiRequest<T>(params: ApiRequestParams): Promise<ApiResponse<T>> {

    let response;
    let offset: number = new Date().getTimezoneOffset();
    let headers = params.extraHeaders || {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Timezone-Offset': offset.toString(),
    };


    let responseType = params.responseType as ResponseType || 'json' as ResponseType;

    const builtHeader: RequestHeader = { ...headers };

    let success = true;

    let error = {
        code: '-1',
        message: '',
        data: null as any,
        statusCode: -1
    };

    let queryParams = params.data && (params.data as any).queryParams ? (params.data as any).queryParams : {};
    let payload = params.data && (params.data as any).payload ? (params.data as any).payload : {};
    let requestConfig: AxiosRequestConfig = {
        url: params.url,
        baseURL: params.urlBase,
        data: payload,
        method: params.method,
        headers: builtHeader as any,
        params: queryParams,
        responseType: responseType,
    }

    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(async (config) => {
        const token = localStorage.getItem('token');

        if (token !== null) {
            // Check if the token is expired or near expiration
            if (isTokenExpired(token)) {
                try {
                    // Refresh the token
                    let tempClient = axios.create({
                        baseURL: config.baseURL
                    });

                    //const response: AxiosResponse<ApiResponse<AuthenticateResponse>> = 
                    await tempClient.post<ApiResponse<AuthenticateResponse>>('/accounts/refresh-token', { withCredentials: true })
                        .then(res => {
                            const newToken = res.data.result.jwtToken;
                            localStorage.setItem('token', newToken);
                            config.headers.Authorization = `Bearer ${newToken}`;
                        })
                        .catch(err => {
                            console.error('Error refreshing token: ', err.message);
                            // this will force them login again
                            localStorage.removeItem('token');
                        });

                } catch (error) {
                    console.error('Error refreshing token:', error);
                }
            } else {
                // Add the token to the request
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        return config;

    });

    try {
        response = await axios.request(requestConfig)
    } catch (err: any) {
        success = false;
        error = {
            code: err ? err.code : '-1',
            message: err ? err.message : '',
            data: (err && err.response) ? err.response.data : null,
            statusCode: (err && err.response) ? err.response.status : -1,
        };
        if (error.statusCode === 401) {
            let msg = error.message
            error.message = msg + ". Please refresh the page if the issue continues."
            error.code = 'ERR_UNAUTHORIZED'
        } else if (error.statusCode === 403) {
            error.code = 'ERR_FORBIDDEN'
        }
        console.log(error)
        throw error;
    }
    return {
        success: success,
        message: success
            ? responseType === "blob"
                ? getFileName(response.headers['content-disposition'])
                : ''
            : error.message,
        result: response
            ? response.data.result
                ? response.data.result
                : response.data
            : error
    };

}

export { apiRequest };