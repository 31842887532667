import { Method } from 'axios';
import { ApiResponse, RequestConfig } from './types';
import { apiRequest } from './api-request';

class ApiClient {
    readonly urlBase: string;

    constructor(urlBase: string) {
        this.urlBase = urlBase;
    }

    request<T>(
        method: Method,
        url: string,
        data?: unknown,
        config?: RequestConfig,
    ): Promise<ApiResponse<T>> {
        return apiRequest<T>({
            url,
            data,
            method,
            urlBase: this.urlBase,
            ...config,
        });
    }

    get<T>(
        url: string,
        data?: unknown,
        config?: RequestConfig,
    ): Promise<ApiResponse<T>> {
        return this.request<T>('GET', url, data, config);
    }

    post<T>(
        url: string,
        data?: unknown,
        config?: RequestConfig,
    ): Promise<ApiResponse<T>> {
        return this.request<T>('POST', url, data, config);
    }

    put<T>(
        url: string,
        data?: unknown,
        config?: RequestConfig,
    ): Promise<ApiResponse<T>> {
        return this.request<T>('PUT', url, data, config); 
    }

    delete<T>(
        url: string,
        data?: unknown,
        config?: RequestConfig,
    ): Promise<ApiResponse<T>> {
        return this.request<T>('DELETE', url, data, config);
    }
    
    //todo: add patch
}

export { ApiClient };