import { apiClient } from '../ApiClient';
import { CustomerCarrierView, PackageModelView, ScanEventHistoryView } from './types';
import { ApiResponse, RequestPayload } from "../ApiClient/types"

class ParcelService {
    /**
     * Returns list of carriers for the particular customer
     * @param payload  = RequestPayload<null> 
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { payload: null }
     * ```
     * @returns CustomerCarrierView
     */
    async GetCarrierList(payload: RequestPayload<null>): Promise<ApiResponse<CustomerCarrierView>> {
        return apiClient.get<CustomerCarrierView>(
            '/parcel/carrier',
            payload
        );
    }

    /**
     * Returns list of Packages for particular Customer and Carrier
     * @param payload = RequestPayload<null>
     * @param queryParams.carrierId: number
     * 
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { queryParams: { carrierId: 12345 }, payload: null }
     * ```
     * @returns  PackageModelView
     */
    async GetParcelList(payload: RequestPayload<null>): Promise<ApiResponse<PackageModelView>> {
        return apiClient.get<PackageModelView>(
            '/parcel/list',
            payload
        );
    }

    /**
     * Returns list of ScanEvent history for particular tracking number
     * @param payload = RequestPayload<null>
     * @param queryParams.trackingNumber: string
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { queryParams: { trackingNumber: '123123123' }, payload: null }
     * ```
     * @returns ScanEventHistoryView[]
     */
    async GetTrackingHistory(payload: RequestPayload<null>): Promise<ApiResponse<ScanEventHistoryView[]>> {
        return apiClient.get<ScanEventHistoryView[]>(
            '/parcel/tracking',
            payload
        );
    }

}

export { ParcelService }