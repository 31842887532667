// import * as fs from 'fs';
import { apiClient } from '../ApiClient';
import { SupportForm } from "./types"
import { ApiResponse, RequestPayload, RequestConfig } from "../ApiClient/types"

class SupportService {
      /**
     * Submits a support request and allows user to upload files
     * @param payload  = RequestPayload<null> 
     
     * @example
     * ```ts
     * let form: SupportForm = { 
     *   name: 'Joe Fetchgoat', 
     *   emailAddress: 'joe@fetchgoat.goat', 
     *   topic: 'Password reset', 
     *   priority: 2, 
     *   subject: 'password', 
     *   message: 'I need help reseting my password' };
     * let payload: RequestPayload<SupportForm> = { payload: form };
     * ```
     * @returns string
     */ 
    async Submit(payload: RequestPayload<SupportForm>): Promise<ApiResponse<string>> {
      const formData = new FormData();
      formData.append('request.Name', payload.payload.Name);
      formData.append('request.EmailAddress', payload.payload.EmailAddress);
      formData.append('request.Topic', payload.payload.Topic);
      formData.append('request.Priority', payload.payload.Priority.toString());
      formData.append('request.Subject', payload.payload.Subject);
      formData.append('request.Message', payload.payload.Message);
      for (const file of payload.payload.Files){
        formData.append('files', file);
      }
      let header: Record<string, string> = { 
        "isFormData": "true",
      };
      let config: RequestConfig = {
        extraHeaders: header
      }
      return apiClient.post<string> (
        '/support/submit',
        formData,
        config
      );
    }
}

export { SupportService }