import { apiClient } from "../ApiClient";
import { ApiResponse } from "../ApiClient/types";
import { ReportViewModel } from "./types";

class AnalyticsService {
    /**
     * Gets the report info needed to display the report
     * @returns ReportViewModel
     */
    async getReportData(): Promise<ApiResponse<ReportViewModel>> {
        return apiClient.get<ReportViewModel>(
            '/analytics'
        );
    }

}

export { AnalyticsService };