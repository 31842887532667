export const statesArray = [
    { key: 'AL', text: 'Alabama', value: 'AL' },
    { key: 'AK', text: 'Alaska', value: 'AK' },
    { key: 'AZ', text: 'Arizona', value: 'AZ' },
    { key: 'AR', text: 'Arkansas', value: 'AR' },
    { key: 'CA', text: 'California', value: 'CA' },
    { key: 'CO', text: 'Colorado', value: 'CO' },
    { key: 'CT', text: 'Connecticut', value: 'CT' },
    { key: 'DE', text: 'Delaware', value: 'DE' },
    { key: 'FL', text: 'Florida', value: 'FL' },
    { key: 'GA', text: 'Georgia', value: 'GA' },
    { key: 'HI', text: 'Hawaii', value: 'HI' },
    { key: 'ID', text: 'Idaho', value: 'ID' },
    { key: 'IL', text: 'Illinois', value: 'IL' },
    { key: 'IN', text: 'Indiana', value: 'IN' },
    { key: 'IA', text: 'Iowa', value: 'IA' },
    { key: 'KS', text: 'Kansas', value: 'KS' },
    { key: 'KY', text: 'Kentucky', value: 'KY' },
    { key: 'LA', text: 'Louisiana', value: 'LA' },
    { key: 'ME', text: 'Maine', value: 'ME' },
    { key: 'MD', text: 'Maryland', value: 'MD' },
    { key: 'MA', text: 'Massachusetts', value: 'MA' },
    { key: 'MI', text: 'Michigan', value: 'MI' },
    { key: 'MN', text: 'Minnesota', value: 'MN' },
    { key: 'MS', text: 'Mississippi', value: 'MS' },
    { key: 'MO', text: 'Missouri', value: 'MO' },
    { key: 'MT', text: 'Montana', value: 'MT' },
    { key: 'NE', text: 'Nebraska', value: 'NE' },
    { key: 'NV', text: 'Nevada', value: 'NV' },
    { key: 'NH', text: 'New Hampshire', value: 'NH' },
    { key: 'NJ', text: 'New Jersey', value: 'NJ' },
    { key: 'NM', text: 'New Mexico', value: 'NM' },
    { key: 'NY', text: 'New York', value: 'NY' },
    { key: 'NC', text: 'North Carolina', value: 'NC' },
    { key: 'ND', text: 'North Dakota', value: 'ND' },
    { key: 'OH', text: 'Ohio', value: 'OH' },
    { key: 'OK', text: 'Oklahoma', value: 'OK' },
    { key: 'OR', text: 'Oregon', value: 'OR' },
    { key: 'PA', text: 'Pennsylvania', value: 'PA' },
    { key: 'RI', text: 'Rhode Island', value: 'RI' },
    { key: 'SC', text: 'South Carolina', value: 'SC' },
    { key: 'SD', text: 'South Dakota', value: 'SD' },
    { key: 'TN', text: 'Tennessee', value: 'TN' },
    { key: 'TX', text: 'Texas', value: 'TX' },
    { key: 'UT', text: 'Utah', value: 'UT' },
    { key: 'VT', text: 'Vermont', value: 'VT' },
    { key: 'VA', text: 'Virginia', value: 'VA' },
    { key: 'WA', text: 'Washington', value: 'WA' },
    { key: 'WV', text: 'West Virginia', value: 'WV' },
    { key: 'WI', text: 'Wisconsin', value: 'WI' },
    { key: 'WY', text: 'Wyoming', value: 'WY' },
    // added Countries
    { key: 'CN-', text: 'China', value: 'CN-' },
    { key: 'US-', text: 'United States', value: 'US-' },
    { key: 'DE-', text: 'Germany', value: 'DE-' },
    { key: 'JP-', text: 'Japan', value: 'JP-' },
    { key: 'NL-', text: 'Netherlands', value: 'NL-' },
    { key: 'FR-', text: 'France', value: 'FR-' },
    { key: 'HK-', text: 'Hong Kong', value: 'HK-' },
    { key: 'KR-', text: 'South Korea', value: 'KR-' },
    { key: 'IT-', text: 'Italy', value: 'IT-' },
    { key: 'CA-', text: 'Canada', value: 'CA-' },
    { key: 'GB-', text: 'United Kingdom', value: 'GB-' },
    { key: 'MX-', text: 'Mexico', value: 'MX-' },
    { key: 'SG-', text: 'Singapore', value: 'SG-' },
    { key: 'RU-', text: 'Russia', value: 'RU-' },
    { key: 'BE-', text: 'Belgium', value: 'BE-' },
    { key: 'TW-', text: 'Taiwan', value: 'TW-' },
    { key: 'ES-', text: 'Spain', value: 'ES-' },
    { key: 'IN-', text: 'India', value: 'IN-' },
    { key: 'TH-', text: 'Thailand', value: 'TH-' },
    { key: 'PL-', text: 'Poland', value: 'PL-' },
    { key: 'AU-', text: 'Australia', value: 'AU-' },
    { key: 'CH-', text: 'Switzerland', value: 'CH-' },
    { key: 'SA-', text: 'Saudi Arabia', value: 'SA-' },
    { key: 'BR-', text: 'Brazil', value: 'BR-' },
    { key: 'SE-', text: 'Sweden', value: 'SE-' },
    { key: 'MY-', text: 'Malaysia', value: 'MY-' },
    { key: 'AT-', text: 'Austria', value: 'AT-' },
    { key: 'TR-', text: 'Turkey', value: 'TR-' },
    { key: 'ID-', text: 'Indonesia', value: 'ID-' },
    { key: 'CZ-', text: 'Czechia', value: 'CZ-' },
];