import { apiClient } from "../ApiClient";
import { ApiResponse, RequestPayload } from "../ApiClient/types";
import { AuthenticateRequest, AuthenticateResponse, RevokeTokenRequest, ForgotPasswordRequest, ValidateResetTokenRequest, ResetPasswordRequest } from "./types";

class AccountService {
    async login(payload: RequestPayload<AuthenticateRequest> ): Promise<ApiResponse<AuthenticateResponse>> {
        return apiClient.post<AuthenticateResponse>(
            `/accounts/authenticate`,
            payload
        );
    }

    async refreshToken(): Promise<ApiResponse<AuthenticateResponse>> {
        return apiClient.post<AuthenticateResponse>(
            `/accounts/refresh-token`
        );
    }

    async revokeToken(payload: RequestPayload<RevokeTokenRequest>): Promise<ApiResponse<null>> {
        return apiClient.post<null>(
            `/accounts/revoke-token`,
            payload
        );
    }

    /**
     * Generates the password reset email
     * @param payload 
     * 
     * let payload: ForgotPasswordRequest = { email: 'my-email@fetchgoat.com' };
     * @returns string
     */
    async forgotPassword(payload: RequestPayload<ForgotPasswordRequest>): Promise<ApiResponse<string>> {
        return apiClient.post<string>(
            `/accounts/forgot-password`,
            payload
        );
    }

    /**
     * Validates the password reset token 
     * @param payload ValidateResetTokenRequest
     * 
     * let payload: ValidateResetTokenRequest = { token: '123124135134124'  };
     * @returns string
     */
    async validateResetToken(payload: RequestPayload<ValidateResetTokenRequest>): Promise<ApiResponse<boolean>> {
        return apiClient.post<boolean>(
            `/accounts/validate-reset-token`,
            payload
        );
    }

    /**
     * Resets the user password 
     * @param payload ResetPasswordRequest
     * 
     * let payload: ResetPasswordRequest = { token: '12341341341`, password: 'my5ecretP@ssword1', confirmpassword: 'my5ecretP@ssword1' }
     * @returns string
     */
    async resetPassword(payload: RequestPayload<ResetPasswordRequest>): Promise<ApiResponse<string>> {
        return apiClient.post<string>(
            `/accounts/reset-password`,
            payload
        );
    }
}

export { AccountService };