import { Dispatch, SetStateAction, useState } from 'react'
import { Button, Form, Icon, Grid } from 'semantic-ui-react';
import { AddressAutofill } from '@mapbox/search-js-react';

type Props = {
    currentShipStep: number,
    setCurrentShipStep: Dispatch<SetStateAction<number>>,
    price?: number,
    selectedCarrier: string,
    updateTable: any,
    handleInputChange: any
}

const OnDemandSearch = (props: Props) => {


    const recOptions = [
        { key: '1', value: 'Receiver1', text: 'Receiver1' },
        { key: '2', value: 'Receiver2', text: 'Receiver2' },
        { key: '3', value: 'Receiver3', text: 'Receiver3' },
        { key: '4', value: 'Receiver4', text: 'Receiver4' },
        { key: '5', value: 'Receiver5', text: 'Receiver5' },
    ]

    const deliveryOpts = [
        { key: '1', value: 'STAT', text: 'STAT' },
        { key: '2', value: 'Special', text: 'Special' },
        { key: '3', value: 'Will Call', text: 'Will Call' },
        { key: '4', value: 'Routine', text: 'Routine' },
        { key: '5', value: 'Route', text: 'Route' },
        { key: '6', value: 'After Hours', text: 'After Hours' },
        { key: '7', value: 'Scheduled Stop', text: 'Scheduled Stop' },
    ]

    const packagesArr = [
        { key: '1', value: 'Box', text: 'Box' },
        { key: '2', value: 'Envelope', text: 'Envelope' },
        { key: '3', value: 'Bag', text: 'Bag' },
    ]

    const getToday = () => {
        let today: string | number | Date = new Date();
        let dd: string | number = today.getDate();
        let mm: string | number = today.getMonth() + 1;
        let yyyy: string | number = today.getFullYear();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return `${yyyy}-${mm}-${dd}`
    }

    const [pickUpTime, setPickUpTime] = useState('')
    const [deliveryDate, setDeliveryDate] = useState(getToday())

    const handleButtonClick = () => {
        // Update the state using the previous value + 1
        setCurrentShipStep(prevStep => prevStep + 1);

        // Call updateTable function
        updateTable();
    };

    const { currentShipStep, setCurrentShipStep, price, selectedCarrier, updateTable, handleInputChange } = props;
    return (
        <>
            <div>
                <Grid>
                    <Grid.Row centered columns={4}>
                        <Grid.Column></Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='Sender Company'
                                    placeholder='Company Name'
                                    name="CompanyName"
                                    onChange={(e, data) => handleInputChange(e, data, true)}
                                />
                                <Form.Input
                                    fluid
                                    label='Sender Name'
                                    placeholder='Sender'
                                    disabled={currentShipStep === 3}
                                    onChange={(e, data) => handleInputChange(e, data, true)}
                                    name='ContactName'
                                />
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='Sender Phone Number'
                                    placeholder='Phone Number'
                                    name="Phone"
                                    onChange={(e, data) => handleInputChange(e, data, true)}
                                />
                                <div className='field'>
                                    <AddressAutofill accessToken={'pk.eyJ1Ijoicmx1ZHdpayIsImEiOiJjbGx3Znlka3Qwc2ZoM2xwNDIwOGtvNGZtIn0.dqrWLAyyZKTU9zreKOwNbw'}>
                                        <Form.Input
                                            fluid
                                            label='Sender Address'
                                            autoComplete="address-line1"
                                            disabled={currentShipStep === 3}
                                            name="Address"
                                            placeholder="Address" type="text"
                                            onChange={(e, data) => handleInputChange(e, data, true)}
                                        />
                                    </AddressAutofill>
                                </div>
                                <Form.Input
                                    fluid
                                    label='City'
                                    autoComplete="address-level2"
                                    disabled={currentShipStep === 3}
                                    name="City" placeholder="City" type="text"
                                    onChange={(e, data) => handleInputChange(e, data, true)}
                                />
                                <Form.Input
                                    fluid
                                    label='State'
                                    autoComplete="address-level1"
                                    disabled={currentShipStep === 3}
                                    name="State" placeholder="State" type="text"
                                    onChange={(e, data) => handleInputChange(e, data, true)}
                                />
                                <Form.Input
                                    fluid
                                    label='Country'
                                    autoComplete="country"
                                    disabled={currentShipStep === 3}
                                    name="Country" placeholder="Country" type="text"
                                    onChange={(e, data) => handleInputChange(e, data, true)}
                                />
                                <Form.Input
                                    fluid
                                    label='Postal Code'
                                    autoComplete="postal-code"
                                    disabled={currentShipStep === 3}
                                    name="PostalCode" placeholder="Zip Code" type="text"
                                    onChange={(e, data) => handleInputChange(e, data, true)}
                                />
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='To'
                                    placeholder='Company Name'
                                    name="CompanyName"
                                    onChange={(e, data) => handleInputChange(e, data, false)}
                                />
                                <Form.Select
                                        style={{ display: 'flex', flexDirection: 'column'}}
                                        label= 'Recipient'
                                        selection
                                        closeOnBlur
                                        disabled={currentShipStep === 3}
                                        placeholder='Select Recipient'
                                        options={recOptions}
                                        name="ContactName"
                                        onChange={(e, data) => handleInputChange(e, data, false)}
                                    />
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='Phone Number'
                                    placeholder='Phone Number'
                                    name="Phone"
                                    onChange={(e, data) => handleInputChange(e, data, false)}
                                />
                                <div className="field">
                                    <AddressAutofill accessToken={'pk.eyJ1Ijoicmx1ZHdpayIsImEiOiJjbGx3Znlka3Qwc2ZoM2xwNDIwOGtvNGZtIn0.dqrWLAyyZKTU9zreKOwNbw'}>
                                        <Form.Input
                                            disabled={currentShipStep === 3}
                                            fluid
                                            label='Address'
                                            autoComplete="address-line1"
                                            placeholder='456 Urban Road'
                                            name="Address"
                                            onChange={(e, data) => handleInputChange(e, data, false)}
                                        />
                                    </AddressAutofill>
                                </div>
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='City'
                                    autoComplete="address-level2"
                                    placeholder='City'
                                    name="City"
                                    onChange={(e, data) => handleInputChange(e, data, false)}
                                />
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='State'
                                    autoComplete="address-level1"
                                    placeholder='State'
                                    name="State"
                                    onChange={(e, data) => handleInputChange(e, data, false)}
                                />
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='Country'
                                    autoComplete="country"
                                    placeholder='Country'
                                    name="Country"
                                    onChange={(e, data) => handleInputChange(e, data, false)}
                                />
                                <Form.Input
                                    disabled={currentShipStep === 3}
                                    fluid
                                    label='Postal Code'
                                    autoComplete="postal-code"
                                    placeholder='Postal Code'
                                    name="PostalCode"
                                    onChange={(e, data) => handleInputChange(e, data, false)}
                                />
                            </Form>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered columns={4}>
                        <Grid.Column>
                            <Form>
                                <Grid.Column >
                                    <Form.Select
                                        label={'Service Type'}
                                        selection
                                        closeOnBlur
                                        disabled={currentShipStep === 3}
                                        placeholder='ServiceType'
                                        options={deliveryOpts}
                                        name="ServiceType"
                                        onChange={handleInputChange}
                                    />
                                </Grid.Column>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Grid.Column>
                                    <Form.Select
                                        label= {'Package Type'}
                                        selection
                                        closeOnBlur
                                        disabled={currentShipStep === 3}
                                        placeholder='PackageType'
                                        options={packagesArr}
                                        name="PackageType"
                                        onChange={handleInputChange}
                                    />
                                </Grid.Column>

                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered columns={4}>
                        <Grid.Column>
                            <Form>
                                <Grid.Column>
                                    {currentShipStep < 3 &&
                                        <Form.Input
                                            onChange={handleInputChange}
                                            disabled={currentShipStep === 3}
                                            min={getToday()}
                                            type='date' fluid label='Pickup Date'
                                            placeholder={getToday()}
                                        />}
                                    {currentShipStep === 3 && <p style={{ color: 'darkgray' }}>{getToday()}</p>}
                                    <Form.Input value={pickUpTime} onChange={(e) => setPickUpTime(e.target.value)} disabled={currentShipStep === 3} type='time' fluid label='Pickup Time' />
                                </Grid.Column>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Grid.Column>
                                    {currentShipStep < 3 && <Form.Input onChange={(e) => setDeliveryDate(e.target.value)} disabled={currentShipStep === 3} min={getToday()} value={deliveryDate} type='date' fluid label='Delivery Date' placeholder='16:08' />}
                                    {currentShipStep === 3 && <p style={{ color: 'darkgray' }}>{deliveryDate}</p>}
                                    <Form.Input value={getToday()} disabled={currentShipStep === 3} type='time' fluid label='Delivery Time' placeholder='16:08' />
                                </Grid.Column>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row centered columns={8}>
                        <Grid.Column>
                            <Form>
                                <div style={{ marginRight: '10rem' }}>
                                    <Form.Input
                                        style={{ width: '6rem' }}
                                        disabled={currentShipStep === 3}
                                        type='number' min={1} fluid
                                        label='Weight(lbs)'
                                        onChange={handleInputChange}
                                        placeholder='1 lb(s)'
                                        name='PackageWeight'
                                    />
                                </div>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Input name='PackageLength' onChange={handleInputChange} style={{ width: '6rem' }} type='number' disabled={currentShipStep === 3} min={1} fluid label='Length' placeholder="1 in(s)" />
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Input name='PackageWidth' onChange={handleInputChange} style={{ width: '6rem' }} type='number' disabled={currentShipStep === 3} min={1} fluid label='Width' placeholder="1 in(s)" />
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Input name='PackageHeight' onChange={handleInputChange} style={{ width: '6rem' }} type='number' disabled={currentShipStep === 3} min={1} fluid label='Height' placeholder="1 in(s)" />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    {currentShipStep === 3 ?
                        <Grid.Row centered columns={4} textAlign='center'>
                            <Grid.Column>
                                <Form>
                                    <Form.Input style={{ width: '8rem' }} disabled={true} type='text' value={selectedCarrier} fluid label='Carrier' />
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <Form>
                                    <Form.Input style={{ width: '8rem' }} disabled={true} type='text' value={price} fluid label='Cost' />
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        :
                        null
                    }
                    <Grid.Row centered columns={currentShipStep === 2 ? 8 : currentShipStep === 3 ? 8 : 4}>
                        <Grid.Column>
                            {currentShipStep > 1 &&
                                <Button onClick={() => setCurrentShipStep(prev => prev - 1)} size='large' color='grey' basic icon labelPosition='left'>
                                    Back
                                    <Icon name='arrow left' />
                                </Button>
                            }
                        </Grid.Column>
                        <Grid.Column>
                            {currentShipStep < 2 &&
                                <Button onClick={handleButtonClick} size='large' color='green'>
                                    Next
                                </Button>
                            }
                        </Grid.Column>
                        <Grid.Column>
                            {currentShipStep === 2 &&
                                <Button onClick={() => updateTable()} size='large' color='green'>Update</Button>
                            }
                        </Grid.Column>
                        <Grid.Column>
                            {currentShipStep === 3 &&
                                <Button onClick={() => setCurrentShipStep(prev => prev + 1)} size='large' color='green'>Submit</Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </>
    )
}

export default OnDemandSearch
