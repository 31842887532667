import { useState, useEffect, useReducer } from 'react'
import { Card, Grid, Button, Form, TextArea, Input, Checkbox, Message, Modal, Table } from 'semantic-ui-react';
import '../styles/Tracking.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { TrackModelView, TrackingInfoQueryParams, TrackingCatagory } from '../services/TrackService/types';
import { trackService } from '../services';
import { formatDate, formatDateAndTime, setToLocalTime } from '../utils/datehelper';
import { RequestPayload } from '../services/ApiClient/types';
import { formatAddress } from '../utils/addressHelper';

export interface TrackingQuery {
  findBy: TrackingCatagory,
  tInfo: string,
  fromDate: Date,
  toDate: Date
}

const Tracking = () => {
  const navigate = useNavigate();
  const [trackingInfo, setTrackingInfo] = useState<TrackModelView[]>(null);
  const [messageVisible, setMessageVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [trackingQuery, setTrackingQuery] = useReducer(
    (state: TrackingQuery, newState: Partial<TrackingQuery>) => ({
      ...state,
      ...newState,
    }),
    {
      findBy: TrackingCatagory.CustomerReference,
      tInfo: "",
      fromDate: new Date(),
      toDate: new Date(),

    }
  );

  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
    localStorage.setItem('route', "Tracking")
  }, [navigate]);

  const handleSubmit = (e: any) => {
    try {
      let query: TrackingInfoQueryParams = {
        findBy: trackingQuery.findBy,
        tInfo: trackingQuery.tInfo.toLocaleString(),
        fromDate: formatDate(trackingQuery.fromDate),
        toDate: formatDate(trackingQuery.toDate)
      }
      let payload: RequestPayload<null> = { queryParams: query, payload: null };
      trackService.GetTrackingInfo(payload)
        .then(response => {
          if (response.success) {
            if (response.result.length === 0) {
              setMessageVisible(true);
            }
            else {
              setTrackingInfo(response.result);
              setModalOpen(true);
            }
          }
        });

    } catch (err) {
      console.log(err);
    }
  }

  const style = {
    headerCell: {
      backgroundColor: "#2d8fc4",
      color: "white",
      border: "0.5px solid #ddd",
      fontSize: '1rem',
      textAlign: 'center',
      whiteSpace: 'pre-line'
    },
    tCell: {
      fontSize: '1rem',
      textAlign: 'center',
    },

  }

  return (
    <div className="tracking-container">
      <div className='tracking-wrapper'>
        <Card className='tracking-card'>
          <Card.Content id="tracking-content">
            <Grid>
              <Grid.Row columns={2} id="tracking-grid-row">
                <Grid.Column width={5}>
                  <span id="tracking-header">Track Shipments</span>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Button disabled={trackingQuery.tInfo.length < 1} primary color='blue' id="tracking-button" onClick={(e) => handleSubmit(e)}>
                    Submit
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
          <Card.Content>
            {messageVisible ?
              <Message
                error
                header='Not Found'
                content={'No shipments were found matching your criteria.'}
                onDismiss={() => setMessageVisible(false)}
              /> : ""
            }
            <Form>
              <Form.Field>
                <span id="tracking-selected">Find by: </span>
              </Form.Field>
              <Form.Group>
                <Form.Field>
                  <Checkbox
                    radio
                    label='Your Reference'
                    value={TrackingCatagory.CustomerReference}
                    checked={trackingQuery.findBy === TrackingCatagory.CustomerReference}
                    onChange={() => setTrackingQuery({ findBy: TrackingCatagory.CustomerReference })}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label='Tracking Number'
                    value={TrackingCatagory.TrackingNumber}
                    checked={trackingQuery.findBy === TrackingCatagory.TrackingNumber}
                    onChange={() => setTrackingQuery({ findBy: TrackingCatagory.TrackingNumber })}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='From Date'
                  placeholder='MM/DD/YYYY'
                >
                  <DatePicker
                    selected={setToLocalTime(trackingQuery.fromDate)}
                    onChange={(date) => { if (date === null) date = new Date(); setTrackingQuery({ fromDate: date }) }}
                    maxDate={setToLocalTime(trackingQuery.toDate)} />
                </Form.Field>
                <Form.Field
                  control={Input}
                  label='To Date'
                  placeholder='MM/DD/YYYY'
                >
                  <DatePicker
                    selected={setToLocalTime(trackingQuery.toDate)}
                    minDate={setToLocalTime(trackingQuery.fromDate)}
                    onChange={(date) => { if (date === null) date = new Date(); setTrackingQuery({ toDate: date }) }} />
                </Form.Field>
              </Form.Group>
              <Form.Field
                control={TextArea}
                label='Tracking or Reference Numbers'
                id="tracking-message"
                placeholder='Enter tracking or reference numbers (Separated by a new line)'
                onChange={(e: any, data: any) => { setTrackingQuery({ tInfo: data.value }) }}
              />
            </Form>
          </Card.Content>
        </Card>
        <Modal
          closeIcon
          size='large'
          onClose={() => { setModalOpen(false) }}
          onOpen={() => setModalOpen(true)}
          open={modalOpen}
        >
          <Modal.Header>Shipment Info</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} style={style.headerCell}>Post Date</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>Stop ID</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>Address</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>Reference</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>Completed Time</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>Pieces</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>Weight</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>POD Name</Table.HeaderCell>
                    <Table.HeaderCell width={1} style={style.headerCell}>POD Signature</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    trackingInfo != null && trackingInfo.length > 0
                      ? trackingInfo.map((row: TrackModelView, index: number) => {
                        return <Table.Row key={index} id="tr">
                          <Table.Cell width={1} style={style.tCell}>{formatDate(row.postdate)}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{row.stopId}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{formatAddress(row.location)}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{row.reference}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{formatDateAndTime(row.timeCompleted)}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{row.pieces}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{row.weight}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{row.podText}</Table.Cell>
                          <Table.Cell width={1} style={style.tCell}>{row.podSignatureSVG}</Table.Cell>
                        </Table.Row>
                      }) : ""
                  }
                </Table.Body>
              </Table>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    </div>

  )
}

export default Tracking