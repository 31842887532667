import moment from 'moment';
import React from 'react'

export const formatDate = (date: any): string => {
      // make sure date is valid date object. 
      date = new Date(date);
      const yyyy = date.getFullYear();
      let mm: number = date.getMonth() + 1; // Months start at 0!
      let dd: number = date.getDate();

      let day = (dd < 10) ? '0' + dd.toString() : dd.toString();
      let month = (mm < 10) ? '0' + mm.toString() : mm.toString();

      return month + '/' + day + '/' + yyyy;
}

export const formatDateAndTime = (date: any): string => {
      // make sure date is valid date object. 
      date = new Date(date);
      // make sure time is local
      date = setToLocalTime(date);
      const yyyy = date.getFullYear();
      let mm: number = date.getMonth() + 1; // Months start at 0!
      let dd: number = date.getDate();
      let hh: number = date.getHours();
      let min: number = date.getMinutes();
      let timeOfDay: string = 'AM';
      if (hh > 12) {
            timeOfDay = 'PM'
            hh = hh - 12
      }
      //handle midnight
      if (hh === 0) {
            hh = 12
      }

      let day = (dd < 10) ? '0' + dd.toString() : dd.toString();
      let month = (mm < 10) ? '0' + mm.toString() : mm.toString();
      let hour = (hh < 10) ? '0' + hh.toString() : hh.toString();
      let minutes = (min < 10) ? '0' + min.toString() : min.toString();

      let formattedDateAndTime: string = month + '/' + day + '/' + yyyy + ' ' + hour + ':' + minutes + ' ' + timeOfDay;
      return formattedDateAndTime

}

export const formatTime = (date: any): string => {
      // make sure date is valid date object. 
      date = new Date(date);
      // make sure time is local
      date = setToLocalTime(date);
      let hh: number = date.getHours();
      let min: number = date.getMinutes();
      let timeOfDay: string = 'AM';
      if (hh > 12) {
            timeOfDay = 'PM'
            hh = hh - 12
      }
      //handle midnight
      if (hh === 0) {
            hh = 12
      }

      let hour = (hh < 10) ? '0' + hh.toString() : hh.toString();
      let minutes = (min < 10) ? '0' + min.toString() : min.toString();

      let formattedTime: string = hour + ':' + minutes + ' ' + timeOfDay;
      return formattedTime

}

// export const formatTimeRaw = (date: any): string => {
//       // make sure date is valid date object. 
//       date = new Date(date);
//       // date = new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset());
//       console.log("formatTimeRaw Date: ", date)
//       console.log("formatTimeRaw Date timezone offset: ", date.getTimezoneOffset(), new Date(date))
//       // raw time
//       let hh: number = date.getHours();
//       let min: number = date.getMinutes();
//       let timeOfDay: string = 'AM';
//       if (hh > 12) {
//             timeOfDay = 'PM'
//             hh = hh - 12
//       }
//       //handle midnight
//       if (hh === 0) {
//             hh = 12
//       }

//       let hour = (hh < 10) ? '0' + hh.toString() : hh.toString();
//       let minutes = (min < 10) ? '0' + min.toString() : min.toString();

//       let formattedTime: string = hour + ':' + minutes + ' ' + timeOfDay;
//       return formattedTime

// }

export const formatTimeRaw = (date: any): string => {
      // make sure date is valid date object.
      date = new Date(date);

      // Convert UTC date to the desired timezone (in this case, local timezone)
      let localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

      // raw time
      let hh: number = localDate.getHours();
      let min: number = localDate.getMinutes();
      let timeOfDay: string = 'AM';
      if (hh > 12) {
            timeOfDay = 'PM'
            hh = hh - 12
      }
      //handle midnight
      if (hh === 0) {
            hh = 12
      }

      let hour = (hh < 10) ? '0' + hh.toString() : hh.toString();
      let minutes = (min < 10) ? '0' + min.toString() : min.toString();

      let formattedTime: string = hour + ':' + minutes + ' ' + timeOfDay;
      return formattedTime
}

export const setToLocalTime = (date: Date): Date => {
      let dateObject = moment(date).toDate();
      return dateObject;
}

export const formatDateAndTimeOnTwoLines = (date: any): JSX.Element => {
      date = new Date(date);
      let line1: string = formatDate(date);
      let line2: string = formatTimeRaw(date);

      let twoLines: JSX.Element = React.createElement('div', null, line1, React.createElement("br"), line2);
      return twoLines;
}

export const isMaxDate = (dateString: any): boolean => {
      const maxDate = moment("9999-12-31");
      const inputDate = moment(dateString);
      return inputDate.isSame(maxDate, 'day');
}

export const isMinDate = (dateString: any): boolean => {
      const minDate = moment("1900-01-01");
      const inputDate = moment(dateString);
      return inputDate.isSame(minDate, 'day');
}

export const isMinOrMaxDate = (dateString: any): boolean => {
      const minDate = moment("1900-01-01");
      const maxDate = moment("9999-12-31");
      const floorDate = moment("0001-01-01")
      const inputDate = moment(dateString);
      return inputDate.isSame(minDate, 'day')
            || inputDate.isSame(maxDate, 'day')
            || inputDate.isSame(floorDate, 'day');
}