const envConfig = require('./config.json');

const envAlias: any = {
    dev: 'development',
    prod: 'production',
    local: 'local',
    demo: 'demo',
    qa: 'qa'
};

export function getConfig() {
    let env: string;

    if (process.env.REACT_APP_ENV === undefined) {
        env = 'local';
    }
    else {
        env = process.env.REACT_APP_ENV;
    }

    return {
        urls: envConfig[envAlias[env]].urls,
        options: envConfig[envAlias[env]].options, 
    }
}
