import { Location } from '../types';

export interface TrackModelView { 
  postdate:  string, 
  stopId:  number, 
  pieces:  number, 
  weight:  number, 
  reference:  string, 
  timeCompleted:  string, 
  podText:  string, 
  podSignatureSVG:  string, 
  podSignatureUrl:  string,
  podSignatureEncoded: string, 
  address: string,
  location: Location
}

export interface TrackingInfoQueryParams {
  /**
   * Tracking catagory. Should be CustomerReferences or TrackingNumber
   */
  findBy: TrackingCatagory,
  /**
   * List of tracking numbers seperated by new lines
   */
  tInfo: string,
  /**
   * From date - specifies the starting date for the search
   */
  fromDate?: string,
  /**
   * To Date = specifies the ending date for the search
   */
  toDate?: string
}

export enum TrackingCatagory {
  CustomerReference = "reference",
  TrackingNumber = "number"
}