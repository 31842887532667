import { apiClient } from '../ApiClient';
import { CourierSearchModel, ShippingRequest, ShippingContact }  from './types';
import { ApiResponse, RequestPayload } from "../ApiClient/types"

class ShipService {
    /**
     * Returns list of recipients for the particular customer
     * @param payload  = RequestPayload<null> 
     * @example
     * ```ts
     * let payload: RequestPayload<null> = { payload: null }
     * ```
     * 
     * @returns ShippingContact[]
     */
    async GetRecipients(payload: RequestPayload<null>): Promise<ApiResponse<ShippingContact[]>> {
        return apiClient.get<ShippingContact[]> (
            '/ship/recipients',
            payload
        );
    }

    /**
     * Returns list of quotes for customer couriers
     * @param payload = RequestPayload<ShippingRequest>
     * @example
     * ```ts
     * let request: ShippingRequest = {
     *   CustomerId: 10002,
     *   ServiceType: 'STAT',
     *   Sender: shippingContact,
     *   Recipient: recipientContact,
     *   PickupDateFrom: '2023-08-01T08:00:00Z',
     *   PickupDateTo: '2023-08-01T13:00:00Z',
     *   DeliveryDateFrom: '2023-08-02T10:00:00Z',
     *   DeliveryDateTo: '2023-08-02T15:00:00Z',
     *   VehicleType: 'Car',
     *   PackageType: 'Box',
     *   PackageWeight: '2',
     *   PackageLength: '10,
     *   PackageWidth: '5',
     *   PackageHeight: '2',
     *   OrderDate: '2023-08-01T12:00:00Z'
     * };
     * let  payload: RequestPayload<ShippingRequest> = { payload: request }
     * ```
     * @returns  CourierSearchModel[]
     */
    async GetCustomerQuotes(payload: RequestPayload<ShippingRequest>): Promise<ApiResponse<CourierSearchModel[]>> {
        return apiClient.post<CourierSearchModel[]> (
            '/ship/quote',
            payload
        );
    }

    /**
     * Created new shipment order
     * @param payload = RequestPayload<ShippingRequest>     
     * 
     * @example
     * ```ts
     * let request: ShippingRequest = {
     *   CustomerId: 10002,
     *   ServiceType: 'STAT',
     *   Sender: shippingContact,
     *   Recipient: recipientContact,
     *   SaveRecipient: true,
     *   Courier: searchModel,
     *   PickupDateFrom: '2023-08-01T08:00:00Z',
     *   PickupDateTo: '2023-08-01T13:00:00Z',
     *   DeliveryDateFrom: '2023-08-02T10:00:00Z',
     *   DeliveryDateTo: '2023-08-02T15:00:00Z',
     *   VehicleType: 'Car',
     *   PackageType: 'Box',
     *   PackageWeight: '2',
     *   PackageLength: '10,
     *   PackageWidth: '5',
     *   PackageHeight: '2',
     *   OrderDate: '2023-08-01T12:00:00Z'
     * };
     * 
     * let payload: RequestPayload<ShippingRequest> = { payload: request }
     * ```
     * @returns int
     */
    async CreateNewOrder(payload: RequestPayload<ShippingRequest>): Promise<ApiResponse<number>> {
        return apiClient.get<number> (
            '/ship/order',
            payload
        );
    }
}

export { ShipService }