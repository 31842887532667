import '../styles/Footer.scss'

const Footer = () => {

  return (
    <div className="footer-container">
      <p className="footer-label">FetchGoat 2024</p>

    </div>
   
  );
}

export default Footer