import { UserPreference, UserRestriction, ApplicationFeature } from "../types"

export interface UserPreferenceView {
    userName: string,
    userPreferences: UserPreference[],
    userRestrictions: UserRestriction,
    applicationFeatures: ApplicationFeature[]
}
export interface UserPerformance {
    setPref: string,
    firstExc: string,
    secondExc: string,
    neuPre: string,
    posPre: string,
    negPre: string,
}
export const defaultUserPerformance = {
    setPref: "",
    firstExc: "",
    secondExc: "",
    neuPre: "",
    posPre: "",
    negPre: "",
}


