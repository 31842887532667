import { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Message, Grid} from 'semantic-ui-react';
import "../styles/Login.scss";
import { accountService } from "../services";
import { RequestPayload } from "../services/ApiClient/types";
import { ForgotPasswordRequest, ValidateResetTokenRequest } from "../services/AccountService/types";
import { useNavigate } from "react-router-dom";
import PasswordResetForm from "../components/PasswordResetForm";

const ForgotPassword = () => {
    let { resetToken } = useParams();
    const [username, setUsername] = useState("");
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [visible, setVisible] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    /**
     * Sends user email to API and generated password reset email
     * 
     * @param email - email address that is going to be sent the reset email. 
     * @returns - TBD
     */
    const handleForgotPassword = (email: string) => {
        let request: ForgotPasswordRequest = {
            email
        };
        let payload: RequestPayload<ForgotPasswordRequest> = { payload: request }; 
        setIsLoading(true);
        // returns string result with check email message.  
        accountService.forgotPassword(payload)
            .then(response => {
                if (response.success) {
                    setApiSuccess(true);
                }
                else {
                    setApiError(true);
                    console.log("API call was not successful");
                }
            })
            .catch(err => {
                setApiError(true);
                console.error(err);
            }).finally(() => setIsLoading(false))
    }

    /**
     * Validates password reset token. If there is a token passed in through the url route it should call this method to validate it
     * 
     * @param resetToken - the token sent in the forgot password email and passed in through the url
     * @returns - TBD
     */
    const validateResetToken = (resetToken: string) => {
        let request: ValidateResetTokenRequest = {
            token: resetToken
        };
        let payload: RequestPayload<ValidateResetTokenRequest> = { payload: request };

        accountService.validateResetToken(payload)
            .then(response => {
                if (response.success) {
                    //response success does not mean that reset token is valid
                    setIsTokenValid(true) //testing
                }
            })
            .catch(err => {
                console.error("ERROR: ", err);
            })
    }

    const handleChange = (e: any) => setUsername(e.target.value);
    const handleDismiss = () => setVisible(false);
    resetToken !== undefined && validateResetToken(resetToken)

    return (
        <>
            <div className='login-form'>
                <div className="grid_container">
                    <Grid columns="1" style={{ height: '0px' }}>
                        <Grid.Row>
                            <Grid.Column xs={1}>
                            </Grid.Column>
                            <Grid.Column xs={8} style={{ display: 'flex', justifyContent: 'center' }} className="grid-column">
                                <div className='resetPass-form'>
                                    {!isTokenValid && resetToken && visible ? (
                                        <Message
                                            error
                                            header='Token is No Longer Valid'
                                            content='Please try and reset your password again'
                                            onDismiss={handleDismiss}
                                        />) : ""
                                    }
                                    {apiSuccess && visible ?
                                        <Message
                                            success
                                            header='Success'
                                            content={`Your request has been received. 
                                            If we have an account matching ${username}, 
                                            you will receive an email with a link to reset your password.` }
                                            onDismiss={handleDismiss}
                                        />
                                        :
                                        ""
                                    }
                                    {apiError && visible ?
                                        <Message
                                            error
                                            header='Error'
                                            content='Please put in a valid email address'
                                            onDismiss={handleDismiss}
                                        /> : ""
                                    }
                                    {resetToken === undefined ?
                                        <Form className='username-section'>
                                            <h2 className='resetPass-header'>Reset My Password</h2>
                                            <p>Please enter your email to reset your password</p>
                                            <Form.Input
                                                className='resetPass-username'
                                                placeholder='Name'
                                                name='username'
                                                value={username}
                                                onChange={handleChange}
                                            />
                                            <Button 
                                                className="resetPass-signIn" 
                                                onClick={() => navigate('/')}>
                                                Click here to sign in
                                            </Button>
                                            <Button
                                                className="resetPass-button"
                                                color="blue"
                                                disabled={isLoading}
                                                onClick={() => handleForgotPassword(username)}
                                            >
                                                {
                                                    !isLoading ? 'Reset My Password' : 
                                                        <Button loading 
                                                                primary
                                                                style= {{padding: "1px 32px", width: "100%", textAlign: "center"}}>
                                                        Loading
,                                                      </Button>
                                                }
                                            </Button>
                                        </Form>
                                        :
                                        <PasswordResetForm resetToken={resetToken} />
                                    }
                                </div>
                            </Grid.Column>
                            <Grid.Column xs={1}>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <div className="login_photo_container">
                    <img className="login_photo" src='/Images/login_picture.jpg' alt="Login" />
                </div>
            </div>
        </>)
}

export default ForgotPassword


